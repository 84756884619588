<script>
export default {
  name: "OrdreTexte"
}
</script>

<template>
  <div class="flex w-full items-center px-6 justify-center mt-56 titre">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">LE CONSEIL DE L'ORDRE DU MERITE MARITIME</h1>
    </div>
  </div>

  <div class="flex w-full items-center px-6 justify-center">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">Les présidents</h1>
    </div>
  </div>
  <section class="flex w-full items-center justify-center p-6">
    <div class="containt flex w-full gap-8 max-w-screen-lg">
      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653] text-center">
        <h1 class="text-2xl">Président</h1>
        <hr class="border w-full flex">
        <h1 class="text-2xl">Monsieur Fabrice LOHERE</h1>
        <p class="h-full flex min-h-24">Ministre délégué auprès de la ministre du Partenariat avec les territoires et de la Décentralisation, chargé de la Mer et de la Pêche</p>
      </div>

      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653] text-center">
        <h1 class="text-2xl">Vice-Président</h1>
        <hr class="border w-full flex">
        <h1 class="text-2xl">Monsieur l'Amiral (2S) Bernard-Antoine MORIO DE L'ISLE</h1>
        <p class="h-full flex min-h-24">Membre du Conseil de l'Ordre de la Légion d'Honneur.</p>
      </div>
    </div>
  </section>

  <div class="flex w-full items-center px-6 justify-center">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">Les membres</h1>
    </div>
  </div>

  <section class="flex w-full items-center justify-center p-6">
    <div class="containt flex w-full gap-8 max-w-screen-lg">
      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653] text-center">
        <h1 class="text-2xl">Monsieur le Conseiller d'Etat Terry OLSON</h1>
        <hr class="border w-full flex">
        <p class="h-full flex min-h-24">Conseiller d'Etat.</p>
      </div>
      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653] text-center">
        <h1 class="text-2xl">Monsieur le Contre-amiral (2S) Jean-Michel MARTINET</h1>
        <hr class="border w-full flex">
        <p class="h-full flex min-h-24">Officier Général de la Marine Nationale.</p>
      </div>
      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653] text-center">
        <h1 class="text-2xl">Monsieur l'Administrateur Général hors classe des Affaires Maritimes Guillaume SELLIER</h1>
        <hr class="border w-full flex">
        <p class="h-full flex min-h-24">Inspecteur Général des Affaires Maritimes.</p>
      </div>
    </div>
  </section>

  <section class="flex w-full items-center justify-center p-6">
    <div class="containt flex w-full gap-8 max-w-screen-lg">
      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653] text-center">
        <h1 class="text-2xl">Monsieur Eric BANEL</h1>
        <hr class="border w-full flex">
        <p class="h-full flex min-h-24">Directeur Général des Affaires Maritimes de la pêche et de l'aquaculture.</p>
      </div>
      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653] text-center">
        <h1 class="text-2xl">Madame Christiane EZCUTARI</h1>
        <hr class="border w-full flex">
        <p class="h-full flex min-h-24">Présidente du Conseil Supérieur de la Marine Marchande.</p>
      </div>
      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653] text-center">
        <h1 class="text-2xl">Monsieur Jean-François JOUFFRAY</h1>
        <hr class="border w-full flex">
        <p class="h-full flex min-h-24">Président du Conseil Supérieur des Gens de Mer.</p>
      </div>
    </div>
  </section>

  <section class="flex w-full items-center justify-center p-6">
    <div class="containt flex w-full gap-8 max-w-screen-lg mb-24">
      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653] text-center">
        <h1 class="text-2xl">Madame Sophie PANONACLE</h1>
        <hr class="border w-full flex">
        <p class="h-full flex min-h-24">Présidente du Bureau du Conseil National de la Mer et des Littoraux.</p>
      </div>
      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653] text-center">
        <h1 class="text-2xl">Monsieur Olivier LE NEZET</h1>
        <hr class="border w-full flex">
        <p class="h-full flex min-h-24">Président du Comité National des Pêches Maritimes et Elevages Marins.</p>
      </div>
      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653] text-center">
        <h1 class="text-2xl">Madame Marie-Luce PENCHARD</h1>
        <hr class="border w-full flex">
        <p class="h-full flex min-h-24">Personnalité ultramarine qualifiée.</p>
      </div>
    </div>
  </section>
</template>

<style scoped>
@media (max-width: 800px){
  .containt{
    flex-direction: column;
  }
}

@media (max-width: 1024px) {
  .titre{
    margin-top: 100px;
  }
}
</style>