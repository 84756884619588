<script>
  export default {
    name : 'Institutionnels'
  }
</script>

<template>
  <div class="flex w-full items-center px-6 justify-center">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">Liens Institutionnels</h1>
    </div>
  </div>

  <div class="flex w-full flex-col mb-12">
    <div class="flex w-full items-center px-6 justify-center">
      <div class="w-full flex max-w-screen-lg flex-col">
        <h1 class="text-3xl pb-2 ">Les Ordres Nationaux</h1>
      </div>
    </div>
    <section class="flex w-full items-center justify-center p-6">
      <div class="containt flex w-full gap-8 max-w-screen-lg">
        <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
          <h1 class="text-2xl text-center">Légion d'honneur</h1>
          <hr class="border w-full flex">
          <a href="https://www.legiondhonneur.fr/fr" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg></a>
        </div>
<!--        <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">-->
<!--          <h1 class="text-2xl text-center">Prefecture maritime de l'Atlantique</h1>-->
<!--          <hr class="border w-full flex">-->
<!--          <a href="https://www.premar-atlantique.gouv.fr/" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg></a>-->
<!--        </div>-->
        <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
          <h1 class="text-2xl text-center">L'Ordre National du Mérite</h1>
          <hr class="border w-full flex">
          <a href="https://www.legiondhonneur.fr/fr/page/lordre-national-du-merite/85" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg></a>
        </div>
      </div>
    </section>
  </div>

  <div class="flex w-full flex-col mb-12">
    <div class="flex w-full items-center px-6 justify-center">
      <div class="w-full flex max-w-screen-lg flex-col">
        <h1 class="text-3xl pb-2">IGAM</h1>
      </div>
    </div>
    <section class="flex w-full items-center justify-center p-6">
      <div class="containt flex w-full gap-8 max-w-screen-lg">
        <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
          <h1 class="text-2xl text-center">Inspection Générale des Affaire Maritimes</h1>
          <hr class="border w-full flex">
          <a href="https://www.igam.developpement-durable.gouv.fr/spip.php?page=sommaire&lang=fr" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg></a>
        </div>
      </div>
    </section>
  </div>

  <div class="flex w-full flex-col mb-12">
    <div class="flex w-full items-center px-6 justify-center">
      <div class="w-full flex max-w-screen-lg flex-col">
        <h1 class="text-3xl pb-2">Direction Générale des Affaires Maritimes de la Pêche et de l' Aquaculture</h1>
      </div>
    </div>
    <section class="flex w-full items-center justify-center p-6">
      <div class="containt flex w-full gap-8 max-w-screen-lg">
        <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
          <h1 class="text-2xl text-center">Direction générale des affaires maritimes</h1>
          <hr class="border w-full flex">
          <a href="https://lannuaire.service-public.fr/gouvernement/b7b3f127-bc9d-47de-8094-cb38525dcfb8" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg></a>
        </div>
      </div>
    </section>
  </div>

  <div class="flex w-full flex-col mb-12">
    <div class="flex w-full items-center px-6 justify-center">
      <div class="w-full flex max-w-screen-lg flex-col">
        <h1 class="text-3xl pb-2 ">LES  PREFECTURES MARITIMES et Services de l'Etat en DOM-TOM</h1>
      </div>
    </div>
    <section class="flex w-full items-center justify-center p-6">
      <div class="containt flex w-full gap-8 max-w-screen-lg">
        <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
          <a href="https://www.premar-manche.gouv.fr/" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded flex items-center w-full justify-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg>Préfecture Maritime de la Manche et de la Mer du Nord</a>
          <a href="https://www.martinique.gouv.fr/" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded flex items-center w-full justify-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg>Préfet de la Martinique</a>
          <a href="https://www.guyane.gouv.fr/" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded flex items-center w-full justify-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg>Préfet de la région Guyane</a>
          <a href="https://www.saint-barth-saint-martin.gouv.fr/" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded flex items-center w-full justify-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg>Préfet de Saint-Barthélemy</a>
          <a href="https://www.saint-barth-saint-martin.gouv.fr/" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded flex items-center w-full justify-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg>Préfet de Saint-Martin</a>
          <a href="https://www.saint-pierre-et-miquelon.gouv.fr/" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded flex items-center w-full justify-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg>Préfet de Saint-Pierre et Miquelon</a>
          <a href="https://www.premar-atlantique.gouv.fr//" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded flex items-center w-full justify-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg>Préfet Maritime de  l'Atlantique</a>
        </div>

        <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
          <a href="https://www.reunion.gouv.fr/" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded flex items-center w-full justify-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg>Préfet de la Réunion</a>
          <a href="https://www.mayotte.gouv.fr/" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded flex items-center w-full justify-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg>Préfet de la Mayotte</a>
          <a href="https://taaf.fr/collectivites/presentation-de-la-collectivite/" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded flex items-center w-full justify-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg>TAAF - Terres Australes</a>
          <a href="https://www.nouvelle-caledonie.gouv.fr/" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded flex items-center w-full justify-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg>Ht-Comm. de la République de Nelle-Calédonie</a>
          <a href="https://www.polynesie-francaise.pref.gouv.fr/" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded flex items-center w-full justify-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg>Ht-Comm. de la République en Polynésie Française</a>
          <a href="https://www.wallis-et-futuna.gouv.fr/" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded flex items-center w-full justify-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg>Préfet des Îles Wallis et Futuna</a>
          <a href="https://www.premar-mediterranee.gouv.fr/" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded flex items-center w-full justify-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg>Préfet Maritime de la Méditerranée</a>
        </div>
      </div>
    </section>
  </div>


  <div class="flex w-full flex-col mb-12">
    <div class="flex w-full items-center px-6 justify-center">
      <div class="w-full flex max-w-screen-lg flex-col">
        <h1 class="text-3xl pb-2">Ministère chargé de Mer et de La Pêche</h1>
      </div>
    </div>
    <section class="flex w-full items-center justify-center p-6">
      <div class="containt flex w-full gap-8 max-w-screen-lg">
        <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
          <h1 class="text-2xl text-center">Ministère chargé de Mer et de La Pêche</h1>
          <hr class="border w-full flex">
          <a href="https://www.mer.gouv.fr/" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg></a>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
@media (max-width: 800px){
  .containt{
    flex-direction: column;
  }
}
</style>